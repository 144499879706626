import Vue from 'vue'
import App from './App.vue'
import createRouter from './router'
import createStore from './store'
import Meta from 'vue-meta'
const placeholderimage = require('@/assets/placeholder.png')
import VueLazyload from 'vue-lazyload'
import global from '@/utils/global'
import { initializeApp } from 'firebase/app'
import { getAnalytics, logEvent, isSupported } from 'firebase/analytics'

Vue.prototype.$global = global

Vue.use(Meta)
Vue.use(VueLazyload, {
	preLoad: 1.3,
	error: placeholderimage,
	loading: placeholderimage,
	attempt: 1,
})

function initializeFirebase() {
	const firebaseConfig = window.__INITIAL_STATE__ ? window.__INITIAL_STATE__.firebaseConfig : global.firebaseConfig
	let app = initializeApp(firebaseConfig)
	let analytics = getAnalytics(app)
	return analytics
}

function setupAnalytics() {
	// // 此代码块只在客户端执行
	isSupported().then((result) => {
		if (result) {
			const analytics = initializeFirebase()
			logEvent(analytics, 'in_page')
			console.log('in_page')
			// app.js 中的全局日志功能
			Vue.prototype.$logEvent = (event, params = {}) => {
				console.log(event)
				logEvent(analytics, event, params)
			}
			Vue.prototype.$eventrackFb = (msg, method, map = {}) => {
				let params = {
					time: new Date(),
					message: msg,
					method: method,
					...map,
				}
				// console.log(params)
				logEvent(analytics, msg, params)
			}
			Vue.prototype.$logger = (message) => {
				if (typeof JSCallAndroid !== 'undefined' && typeof JSCallAndroid.log === 'function') {
					JSCallAndroid.log(message)
				} else {
					console.log(message)
				}
			}
			Vue.prototype.$receiveMsg = (message) => {
				if (typeof JSCallAndroid !== 'undefined' && typeof JSCallAndroid.receiveMsg === 'function') {
					JSCallAndroid.receiveMsg(message)
				} else {
					console.log(message)
				}
			}
			Vue.prototype.$adClick = (message) => {
				if (typeof JSCallAndroid !== 'undefined' && typeof JSCallAndroid.adClick === 'function') {
					JSCallAndroid.adClick(message)
				} else {
					console.log(message)
				}
			}
		} else {
			// 如果 Firebase Analytics 不支持
			console.log('Firebase Analytics not support')
			Vue.prototype.$logEvent = (event, params = {}) => {
				console.log(`Analytics not supported: ${event}`, params)
			}
			Vue.prototype.$logger = (event, params = {}) => {
				console.log(`Analytics not supported: ${event}`, params)
			}
			Vue.prototype.$eventrackFb = (event, params = {}) => {
				console.log(`Analytics not supported: ${event}`, params)
			}
			Vue.prototype.$logger = (msg) => {
				console.log(`Analytics not supported: ${msg}`, msg)
			}
			Vue.prototype.$receiveMsg = (msg) => {
				console.log(`Analytics not supported: ${msg}`, msg)
			}
			Vue.prototype.$adClick = (msg) => {
				console.log(`Analytics not supported: ${msg}`, msg)
			}
		}
	})
}

if (typeof window !== 'undefined') {
	setupAnalytics()
} else {
	// 对于服务器端，你可以定义一个简单的函数或者不做任何事情
	Vue.prototype.$logEvent = (event, params = {}) => {
		console.log(`Server Log: ${event}`, params)
	}
	Vue.prototype.$eventrackFb = (event, params = {}) => {
		console.log(`Server Log: ${event}`, params)
	}
	Vue.prototype.$logger = (msg) => {
		console.log(`Analytics not supported: ${msg}`, msg)
	}
	Vue.prototype.$receiveMsg = (msg) => {
		console.log(`Analytics not supported: ${msg}`, msg)
	}
	Vue.prototype.$adClick = (msg) => {
		console.log(`Analytics not supported: ${msg}`, msg)
	}
}

// simple event

export default function createApp() {
	const router = createRouter()
	const store = createStore()
	const app = new Vue({
		router,
		store,
		render: (h) => h(App),
	})
	return {
		app,
		router,
		store,
	}
}
