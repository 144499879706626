import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
/* 解决访问重复路由报错问题：NavigationDuplicated: Avoided redundant navigation to current location: "/xxx" 开始 */
const originalPush = VueRouter.prototype.push
// 修改 原型对象中的push方法
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err)
}

/* 解决访问重复路由报错问题：NavigationDuplicated: Avoided redundant navigation to current location: "/xxx" 结束 */

let routes = [
	{
		path: '/',
		name: 'home',
		// component: () => import('../pages/home.vue'),
		component: () => import(/* webpackChunkName: "home" */ '../pages/home.vue'),
	},
	{
		path: '/home',
		name: 'homepage',
		// component: () => import('../pages/home.vue'),
		component: () => import(/* webpackChunkName: "home" */ '../pages/home.vue'),
	},
	{
		path: '/privacypolicy',
		name: 'privacy',
		component: () => import('../pages/privacy.vue'),
	},
	{
		path: '/termsofservice',
		name: 'terms',
		component: () => import('../pages/terms.vue'),
	},
	{
		path: '/landing',
		name: 'landing',
		component: () => import('../pages/landing.vue'),
	},
	{
		path: '/blanding',
		name: 'blanding',
		component: () => import('../pages/bees_landing.vue'),
	},
	{
		path: '/clock',
		name: 'clock',
		component: () => import('../pages/clock.vue'),
	},
	// {
	// 	path: '/sanproject',
	// 	name: 'sanproject',
	// 	component: () => import('../pages/home.vue'),
	// },
	{
		path: '/:channel',
		name: 'homechannel',
		component: () => import(/* webpackChunkName: "home" */ '../pages/home.vue'),
	},
	{
		path: '/:channel/landing',
		name: 'landingchannel',
		component: () => import('../pages/landing.vue'),
	},
]

export default function createRouter() {
	return new VueRouter({
		mode: 'history',
		routes,
		scrollBehavior(to, from, savedPosition) {
			if (savedPosition) {
				return savedPosition
			} else {
				return { x: 0, y: 0 }
			}
		},
	})
}
