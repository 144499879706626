import createApp from './src/app.js'

const { app, router, store } = createApp()

// // 检查是否存在服务器端注入的状态
if (window.__INITIAL_STATE__) {
	store.replaceState(window.__INITIAL_STATE__)
}

router.onReady(() => {
	// 挂载Vue实例时，将服务端的context注入
	app.$mount('#app')
})
